import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function createAdminApiEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.ADMIN_CREATE, data);
}

function updateAdminApiEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.ADMIN_UPDATE, data);
}

function getAdminApiEndPoint(encryptedParams: any): any {
    return api.get(`${URL.ADMIN_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

export { createAdminApiEndPoint, getAdminApiEndPoint, updateAdminApiEndPoint };
