import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminCompanyActionTypes } from './constants';
import { adminNdSiteAdminComapnyApiEndPoint, getPlanWithCompanyIdEndpoint } from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* callGetCompanyByAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminComapnyApiEndPoint);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callGetPlanByCompanyId(action) {
    try {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(getPlanWithCompanyIdEndpoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List

export function* getCompanyAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN,
        callGetCompanyByAdminNdSiteAdmin
    );
    yield takeEvery(
        AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID,
        callGetPlanByCompanyId
    );
}

function* getCompanyAdminNdSiteAdminSaga(): any {
    yield all([fork(getCompanyAdminNdSiteAdminAction)]);
}

export default getCompanyAdminNdSiteAdminSaga;
