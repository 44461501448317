import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
const api = new APICore();

// function getHardwareEndPoint(params: any): any {
//     const { search, page, limit, company_id } = params?.data;
//     return api.get(`${URL.GET_HARDWARE}company_id=${company_id}&search=${search}&page=${page}&limit=${limit}`);
// }
function getHardwareEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_HARDWARE}payload=${encodeURIComponent(encryptedParams)}`);
};


function createHardwareEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.CREATE_HARDWARE, data);
};


// function createHardwareEndPoint(params: any): any {
//     const { data } = params;
//     return api.create(`${URL.CREATE_HARDWARE}`, data);
// }

// function deleteHardwareEndPoint(params: any): any {
//     const { data } = params;
//     return api.create(`${URL.DELETE_HARDWARE}`, data);
// }

function deleteHardwareEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.DELETE_HARDWARE, data);
};

export { getHardwareEndPoint, createHardwareEndPoint, deleteHardwareEndPoint }