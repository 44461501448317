import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from '../../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminPostAddSoftwareEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_SOFTWARE, data);
}

function adminNdSiteAdminGetSoftwareEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_SOFTARE}payload=${encodeURIComponent(encryptedParams)}`);
}

function getItDashboardEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.GET_IT_DASHBOARD, data);
}
function ipAddressAdminNdSiteAdminEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_IP_ADDRESS, data);
}

function getTotalSoftwareAssetsEndPoint(encryptedParams: any): any {
    return api.get(`${URL.TOAT_SOFTWARE_ASSETS_VULN}${encodeURIComponent(encryptedParams)}`);
}

function getActiveUsers(encryptedPayload: any): any {
    return api.get(URL.GET_ACTIVE_USERS, encodeURIComponent(encryptedPayload));
}

function adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_VULNERABILITIES_DEFAULT, data);
}



export {
    adminNdSiteAdminPostAddSoftwareEndPoint,
    adminNdSiteAdminGetSoftwareEndPoint,
    getItDashboardEndPoint,
    ipAddressAdminNdSiteAdminEndPoint,
    getTotalSoftwareAssetsEndPoint,
    getActiveUsers,
    adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint,
};
