import CryptoJS from 'crypto-js';
import config from '../config';

const secretKey = config?.SECRET_KEY;

// Function to encrypt payload
export const encryptPayload = (payload) => {
    try {
        // Ensure payload is valid before encryption
        if (!payload || typeof payload !== 'object') {
            console.error('Invalid payload for encryption');
            return null; // Return null or handle the error case
        }

        // Encrypt the payload and convert it to a ciphertext string
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), secretKey).toString();

        // Optionally, you can URL-encode the ciphertext if required
        // const encodedCiphertext = encodeURIComponent(ciphertext);

        // Log the ciphertext for debugging (remove in production)
        // console.log('Encrypted payload (ciphertext):', ciphertext);

        return ciphertext;
    } catch (error) {
        console.error('Error during encryption:', error);
        return null; // Return null or handle accordingly
    }
};

// Function to decrypt payload
export const decryptPayload = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

        // Check if the decrypted string is valid before parsing
        if (!decryptedString) {
            console.error('Decryption failed: empty or invalid decrypted string');
            return null; // Return null or handle accordingly
        }

        // Log the decrypted string for debugging
        // console.log('Decrypted string (raw):', decryptedString);

        // Now parse the decrypted string into JSON
        const decryptedData = JSON.parse(decryptedString);

        // console.log({ encryptedData, decryptedData }, 'fnctn');
        // console.log({ decryptedData }, 'fnctn');
        return decryptedData;
    } catch (error) {
        console.error('Error during decryption or JSON parsing:', error);
        return null; // Return null or handle accordingly
    }
};


