import { reportActionTypes } from "./constants";


export const postReportAction = (data): LayoutAction => ({
    type: reportActionTypes.POST_REPORT_FIRST,
    data,
});

export const getReportAction = (data): LayoutAction => ({
    type: reportActionTypes.GET_REPORT_FIRST,
    data,
});