import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';

const api = new APICore();


function adminNdSiteAdminAccountsGetApiEndPoint(encryptedParams: string): any {
    return api.get(`${URL.ACCOUNT_GET}payload=${encodeURIComponent(encryptedParams)}`);
};


function adminNdSiteAdminAccountsAddApiEndPoint(encryptedPayload: any): any {
    const accountData = {
        payload: encryptedPayload
    }
    return api.create(URL.ACCOUNT_ADD, accountData);
}

function adminNdSiteAdminAccountsEditApiEndPoint(encryptedPayload: any): any {
    const accountData = {
        payload: encryptedPayload
    };
    return api.update(URL.ACCOUNT_EDIT, accountData);
}

function adminNdSiteAdminAccountsStatusApiEndPoint(encryptedPayload: any): any {
    const accountData = {
        payload: encryptedPayload
    };
    return api.create(URL.ACCOUNT_STATUS, accountData);
}

function adminNdSiteAdminAccountsGetGroupsApiEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GROUPS_LIST_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

function adminNdSiteAdminAccountsAssignGroupsApiEndPoint(encryptedPayload: any): any {
    const accountData = {
        payload: encryptedPayload

    };
    return api.create(URL.ACCOUNT_ASSIGN_GROUPS, accountData);
}

function adminNdSiteAdminAccountsUserPasswordResetApiEndPoint(encryptedPayload: any): any {
    const id = {
        payload: encryptedPayload
    };
    return api.create(URL.ACCOUNT_USER_PASSWORD_RESET, id);
}


export {
    adminNdSiteAdminAccountsGetApiEndPoint,
    adminNdSiteAdminAccountsAddApiEndPoint,
    adminNdSiteAdminAccountsEditApiEndPoint,
    adminNdSiteAdminAccountsStatusApiEndPoint,
    adminNdSiteAdminAccountsGetGroupsApiEndPoint,
    adminNdSiteAdminAccountsAssignGroupsApiEndPoint,
    adminNdSiteAdminAccountsUserPasswordResetApiEndPoint
};
