import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
const api = new APICore();

function getIpAddressEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_IP_ADDRESS}payload=${encodeURIComponent(encryptedParams)}`);
}

function getIpAddressInfoEndPoint(encryptedParams: any): any {
    return api.get(URL.GET_IP_ADDRESS_INFO + encodeURIComponent(encryptedParams));
}
function statusIpAddressEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.STATUS_IP_ADDRESS, data);
}

function getIpAddressScanEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_IP_ADDRESS_SCAN_SCHLDULE}payload=${encodeURIComponent(encryptedParams)}`);
}

function getPreviousIpAddressScanListEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST}payload=${encodeURIComponent(encryptedParams)}`);
}

function getIpAddressScanListEndPoint(encryptedParams: any): any {

    return api.get(`${URL.GET_IP_ADDRESS_SCAN_SCHLDULE_LIST}${encodeURIComponent(encryptedParams)}`);
}
function addIpScanScheduleEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.ADD_IP_ADDRESS_SCAN_SCHLDULE, data);
}
function editIpScanScheduleEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.update(URL.EDIT_IP_ADDRESS_SCAN_SCHLDULE, data);
}
function addIpScanEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.ADD_IP_ADDRESS_ON_SCAN_SCHLDULE, data);
}
export {
    getIpAddressEndPoint,
    getIpAddressInfoEndPoint,
    getIpAddressScanEndPoint,
    getPreviousIpAddressScanListEndPoint,
    addIpScanScheduleEndPoint,
    editIpScanScheduleEndPoint,
    getIpAddressScanListEndPoint,
    statusIpAddressEndPoint,
    addIpScanEndPoint
};
