import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import ToastHandle from '../helpers/ToastMessage';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { getUserFromSession } from '../helpers/api/apiCore';
import ActionButton from '../helpers/ActionButton/ActionButton';
import { useSelector } from 'react-redux';
import config from '../config';
import NetworkHandler from '../helpers/NetworkHandler';

const socket = io.connect(config?.API_URL, {
    autoConnect: false,
});
const Routes = () => {
    const store = useSelector((state) => state);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const userInfo = getUserFromSession();
    const USERID = userInfo?.id
    const Loggedin = store?.Auth?.userLoggedIn;
    const LoggedOut = store?.Auth?.userLogout;
    const [notification, setNotification] = useState()
    const [userID, setUserID] = useState()
    socket.on('notifyUser', (data) => {
        setNotification(data?.notifications)
        setUserID(data?.userId)
    })
    useEffect(() => {
        if (userID && userID === USERID) {
            ToastHandle(notification, 'success');
        }
    }, [notification, userID, USERID]);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);
    return (
        <BrowserRouter>
            <NetworkHandler >
                <AllRoutes />
                {Loggedin !== false && !LoggedOut && isOnline ? <ActionButton /> : <></>}
            </NetworkHandler>
        </BrowserRouter>
    );
};

export default Routes;
