import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
import { decryptPayload } from '../../CryptoUtil';
const api = new APICore();

// function getPlansEndPoint(params: any): any {
//     const { email } = params?.data;
//     return api.get(`${URL.GET_PLANS}${email}`);
// }

function getPlanTypeEndPoint(params: any): any {
    return api.get(`${URL.GET_PLAN_TYPE}`);
}

function createPlanEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(`${URL.CREATE_PLAN}`, data);
}

function getPlansEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_PLANS}${encryptedParams}`);
};

function getPlanSubscriptionByIdEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_PLAN_SUBSCRIPTION_BY_ID}${encodeURIComponent(encryptedParams)}`);
};

function verifyOtpEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(`${URL.VERIFY_TOKEN}`, data);
};

export { getPlanTypeEndPoint, createPlanEndPoint, getPlansEndPoint, getPlanSubscriptionByIdEndPoint, verifyOtpEndPoint };
