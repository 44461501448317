import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminVChartGetApiEndPoint(encryptedParams: any): any {
    return api.get(`${URL.ASSETS_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

function adminNdSiteAdminVChartAddApiEndPoint(encryptedPayload: any): any {
    const AddVChartData = {
        payload: encryptedPayload
    };
    return api.create(URL.ASSETS_Add, AddVChartData);
}

function adminNdSiteAdminVChartEditApiEndPoint(encryptedPayload: any): any {
    const AddVChartData = {
        payload: encryptedPayload
    };
    return api.update(URL.ASSETS_Edit, AddVChartData);
}

function adminNdSiteAdminVChartStatusApiEndPoint(encryptedPayload: any): any {
    const statusVChartData = {
        payload: encryptedPayload
    };
    return api.create(URL.ASSETS_Status, statusVChartData);
}

function adminNdSiteAdminVChartCsvApiEndPoint(encryptedPayload: any): any {
    const csvVChartData = {
        payload: encryptedPayload
    };
    return api.create(URL.ASSETS_CSV, csvVChartData);
}

export {
    adminNdSiteAdminVChartGetApiEndPoint,
    adminNdSiteAdminVChartAddApiEndPoint,
    adminNdSiteAdminVChartEditApiEndPoint,
    adminNdSiteAdminVChartStatusApiEndPoint,
    adminNdSiteAdminVChartCsvApiEndPoint,
};
