import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';


const api = new APICore();


function getSoftareEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_SOFTARE}payload=${encodeURIComponent(encryptedParams)}`);
};

function getTaskEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_TASK}${encodeURIComponent(encryptedParams)}`);
};

function addTaskEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }; return api.create(URL.ADD_TASK, data);
}
function addScanScheduleEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }; return api.create(URL.ADD_SOFTWARE_SCAN, data);
}

function addSoftwareScanEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }; return api.create(URL.ADD_SOFTWARE_ON_SCANNING, data);
}

function getScheduleRepeatEndPoint(params: any): any {
    return api.get(URL.GET_REPEAT_SCHEDULE);
}

function getSoftwareNameEndpoint(encryptedParams: any): any {
    return api.get(`${URL.GET_SOFTWARE_NAME}${encodeURIComponent(encryptedParams)}`);

}

function getUserMailEndpoint(params: any): any {
    return api.get(URL.GET_USER_MAIL);
}
function getScanEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_SCAN}payload=${encodeURIComponent(encryptedParams)}`);
}

function getPreviousScan(encryptedParams: any): any {
    return api.get(`${URL.GET_PREVIOUS_SCAN}payload=${encodeURIComponent(encryptedParams)}`);
}

function getPreviousAsset(encryptedParams: any): any {
    return api.get(
        `${URL.GET_PREVIOUS_ASSET}payload=${encodeURIComponent(encryptedParams)}`
    );
}

function deleteSoftwareEndPoint(params) {
    const { id } = params || {};
    if (id) {
        return api.create(`${URL.DELETE_SOFTWARE}/${id?.softwareIdToDelete}/${id?.flag}`);
    } else {
        throw new Error('Invalid software ID');
    }
}
function editScanScheduleEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.update(URL.EDIT_SCAN_SCHEDULE, data);
}
function statusSoftwareEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }; return api.update(URL.STATUS_SOFTWARE, data);
}

function getUserEndpoint(encryptedParams: any): any {
    return api.get(`${URL.GET_USER}${encodeURIComponent(encryptedParams)}`);
}

export {
    getSoftareEndPoint,
    getTaskEndPoint,
    addTaskEndPoint,
    getScheduleRepeatEndPoint,
    getSoftwareNameEndpoint,
    getUserMailEndpoint,
    getScanEndPoint,
    getPreviousScan,
    getPreviousAsset,
    addScanScheduleEndPoint,
    deleteSoftwareEndPoint,
    editScanScheduleEndPoint,
    statusSoftwareEndPoint,
    addSoftwareScanEndPoint,
    getUserEndpoint,
};
