import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { EmailVerifyActionTypes } from './constants';
import { emailVerifyEndPoint, resetPasswordConfirmEndPoint } from './api';
import { encryptPayload, decryptPayload } from '../../CryptoUtil'
function* callEmailVerify(action) {
    try {
        yield put({
            type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_LOADING,
            payload: {},
        });
        // const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(emailVerifyEndPoint, action?.id);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR,
            payload: error,
        });
    }
}


// end cash day Report List

function* callResetPasswordConfirm(data) {
    try {
        yield put({
            type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(data);
        const response = yield call(resetPasswordConfirmEndPoint, data);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR,
            payload: {},
        });
    }
}

export function* emailVerifySecond(): any {
    yield takeEvery(EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_FIRST, callEmailVerify);
}
export function* callResetPasswordConfirmSaga(): any {
    yield takeEvery(EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_FIRST, callResetPasswordConfirm);
}

function* emailVerifySaga(): any {
    yield all([fork(emailVerifySecond), fork(callResetPasswordConfirmSaga)]);
}

export default emailVerifySaga;
