import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { RiskDahboardActionTypes } from './constant';
import { addRiskAssetEndPoint, getDashboardEndPoint, getVulnerabilityEndPoint, japaneseRemedationEndPoint, riskRemedationEndPoint } from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';

// start cash day Report

function* callGetRiskdashboard(action) {
    try {
        yield put({
            type: RiskDahboardActionTypes.GET_RISKDASHBOARD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getDashboardEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: RiskDahboardActionTypes.GET_RISKDASHBOARD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR,
            payload: error,
        });
    }
}

function* callGetRiskVulnerability(action) {
    try {
        yield put({
            type: RiskDahboardActionTypes.GET_VULNERABILITY_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getVulnerabilityEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: RiskDahboardActionTypes.GET_VULNERABILITY_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: RiskDahboardActionTypes.GET_VULNERABILITY_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: RiskDahboardActionTypes.GET_VULNERABILITY_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.GET_VULNERABILITY_ERROR,
            payload: error,
        });
    }
}

function* callRiskRemedation(action) {
    try {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(riskRemedationEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: RiskDahboardActionTypes.POST_RISK_REMEDATION_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR,
            payload: error,
        });
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_RESET,
            payload: {},
        });
    }
}
function* callAddRiskAsset(action) {
    try {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_ASSET_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(addRiskAssetEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: RiskDahboardActionTypes.POST_RISK_ASSET_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: RiskDahboardActionTypes.POST_RISK_ASSET_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: RiskDahboardActionTypes.POST_RISK_ASSET_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_ASSET_ERROR,
            payload: error,
        });
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_ASSET_RESET,
            payload: {},
        });
    }
}

function* callJapaneseRemedation(action) {
    try {
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(japaneseRemedationEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR,
            payload: error,
        });
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_RESET,
            payload: {},
        });
    }
}

// end cash day Report List
export function* getRiskDashboardAction(): any {
    yield takeEvery(RiskDahboardActionTypes.GET_RISKDASHBOARD_FIRST, callGetRiskdashboard);
}
export function* getVulAction(): any {
    yield takeEvery(RiskDahboardActionTypes.GET_VULNERABILITY_FIRST, callGetRiskVulnerability);
    yield takeEvery(RiskDahboardActionTypes.POST_RISK_REMEDATION_FIRST, callRiskRemedation);
    yield takeEvery(RiskDahboardActionTypes.POST_RISK_ASSET_FIRST, callAddRiskAsset);
    yield takeEvery(RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_FIRST, callJapaneseRemedation);
}

function* getRiskdashboardSaga(): any {
    yield all([fork(getRiskDashboardAction)]);
    yield all([fork(getVulAction)]);

}

export default getRiskdashboardSaga;
