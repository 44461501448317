import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetDataMngmntEndPoint(encryptedParams: any): any {
    return api.get(`${URL.DATA_MNGMNT_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

function adminNdSiteAdminAddDataMngmntEndPoint(encryptedPayload: any): any {
    const DataMngmntData = {
        payload: encryptedPayload
    };
    return api.create(URL.DATA_MNGMNT_ADD, DataMngmntData);
}

function adminNdSiteAdminEditDataMngmntEndPoint(encryptedPayload: any): any {
    const DataMngmntData = {
        payload: encryptedPayload
    };
    return api.update(URL.DATA_MNGMNT_EDIT, DataMngmntData);
}

function adminNdSiteAdminCsvDataMngmntEndPoint(encryptedPayload: any): any {
    const DataMngmntData = {
        payload: encryptedPayload
    };
    return api.create(URL.DATA_MNGMNT_CSV, DataMngmntData);
}

function adminNdSiteAdminStatusDataMngmntEndPoint(encryptedPayload: any): any {
    const DataMngmntData = {
        payload: encryptedPayload
    };
    return api.create(URL.DATA_MNGMNT_STATUS, DataMngmntData);
}

function adminNdSiteAdminAssignGroupsDataMngmntEndPoint(encryptedParams: any): any {
    return api.get(`${URL.DATA_MNGMNT_ASSIGN_GROUPS}payload=${encodeURIComponent(encryptedParams)}`);
}

function adminNdSiteAdminGetGroupsDataMngmntEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GROUPS_LIST_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

function adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.DATA_MNGMNT_ADD_GROUPS_ASSETS, data);
}
export {
    adminNdSiteAdminGetDataMngmntEndPoint,
    adminNdSiteAdminAddDataMngmntEndPoint,
    adminNdSiteAdminEditDataMngmntEndPoint,
    adminNdSiteAdminCsvDataMngmntEndPoint,
    adminNdSiteAdminStatusDataMngmntEndPoint,
    adminNdSiteAdminAssignGroupsDataMngmntEndPoint,
    adminNdSiteAdminGetGroupsDataMngmntEndPoint,
    adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint,
};
