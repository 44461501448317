import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { UserNewSFildActionTypes } from './constants';
import { getNewFildEndPoint, } from './api';
import { decryptPayload, encryptPayload } from '../../../CryptoUtil';


// start cash day Report

function* callGetNewsFild(action) {
    try {
        yield put({
            type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.id);

        const response = yield call(getNewFildEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR,
            payload: error,
        });
    }
}
// end cash day Report List


export function* getNewFildAction(): any {
    yield takeEvery(UserNewSFildActionTypes.GET_NEWS_FILD_USER, callGetNewsFild);
}


function* getNewFildSaga(): any {
    yield all([
        fork(getNewFildAction),
    ]);
}

export default getNewFildSaga;
