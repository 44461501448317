import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminAssetsActionTypes } from './constants';
import {
    adminNdSiteAdminAssetsGetApiEndPoint,
    adminNdSiteAdminAssetsAddApiEndPoint,
    updateAssetsEndPoint,
    adminNdSiteAdminAssetsStatusApiEndPoint,
    adminNdSiteAdminAssetsCsvApiEndPoint,
    getSoftwareListEndPoint,
    deleteAssetsEndpoint,
    getLatestVersion,
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';

// start cash day Report

// function* callGetAssetsByCompanyIdAdminNdSiteAdmin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(adminNdSiteAdminAssetsGetApiEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//     }
// }


function* callGetAssetsByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(adminNdSiteAdminAssetsGetApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

function* callGetSoftwareList(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(getSoftwareListEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

// function* callGetSoftwareList(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_LOADING,
//             payload: {},
//         });
//         const response = yield call(getSoftwareListEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR,
//             payload: error,
//         });
//     }
// }


// function* callGetLatestVersion(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_LOADING,
//             payload: {},
//         });
//         const response = yield call(getLatestVersion, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_ERROR,
//             payload: error,
//         });
//     }
// }

function* callGetLatestVersion(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getLatestVersion, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION_ERROR,
            payload: error,
        });
    }
}


// function* callDeleteAsset(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_LOADING,
//             payload: {},
//         });
//         const response = yield call(deleteAssetsEndpoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR,
//             payload: error,
//         });
//     }
// }

function* callDeleteAsset(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(deleteAssetsEndpoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR,
            payload: error,
        });
    }
}

// function* callAddAssetsByCompanyIdAdminNdSiteAdmin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(adminNdSiteAdminAssetsAddApiEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//             payload: {},
//         });
//     }
// }

function* callAddAssetsByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(adminNdSiteAdminAssetsAddApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

// function* callEditAssetsByCompanyIdAdminNdSiteAdmin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(updateAssetsEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//             payload: {},
//         });
//     }
// }

function* callEditAssetsByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(updateAssetsEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

// function* callStatusAssetsByCompanyIdAdminNdSiteAdmin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(adminNdSiteAdminAssetsStatusApiEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//             payload: {},
//         });
//     }
// }

function* callStatusAssetsByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(adminNdSiteAdminAssetsStatusApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

// function* callCsvAssetsByCompanyIdAdminNdSiteAdmin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(adminNdSiteAdminAssetsCsvApiEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//         yield put({
//             type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
//             payload: {},
//         });
//     }
// }

function* callCsvAssetsByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(adminNdSiteAdminAssetsCsvApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response?.data?.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminAssetsActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });
}
// end cash day Report List

export function* getAssetsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callGetAssetsByCompanyIdAdminNdSiteAdmin
    );
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST,
        callGetSoftwareList
    );
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.LATEST_VERSION,
        callGetLatestVersion
    );
}

export function* addAssetsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callAddAssetsByCompanyIdAdminNdSiteAdmin
    );
}

export function* EditAssetsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callEditAssetsByCompanyIdAdminNdSiteAdmin
    );
}

export function* statusAssetsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callStatusAssetsByCompanyIdAdminNdSiteAdmin
    );
}

export function* csvAssetsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callCsvAssetsByCompanyIdAdminNdSiteAdmin
    );
}
export function* deleteAssetAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS,
        callDeleteAsset
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(AdminNdSiteAdminAssetsActionTypes.STATE_EMPTY_INTIAL_STATE, callStateEmpty);
}

function* getAssetsByCompanyIdAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getAssetsByComapnyIdAdminNdSiteAdminAction),
        fork(addAssetsByComapnyIdAdminNdSiteAdminAction),
        fork(EditAssetsByComapnyIdAdminNdSiteAdminAction),
        fork(statusAssetsByComapnyIdAdminNdSiteAdminAction),
        fork(csvAssetsByComapnyIdAdminNdSiteAdminAction),
        fork(deleteAssetAction),
        fork(stateEmptyAction),
    ]);
}

export default getAssetsByCompanyIdAdminNdSiteAdminSaga;
