export const OrganisationActionTypes = {

    GET_ORGANISATION_FIRST: '@@/organisation/GET_ORGANISATION_FIRST',
    GET_ORGANISATION_LOADING: '@@/organisation/GET_ORGANISATION_LOADING',
    GET_ORGANISATION_SUCCESS: '@@/organisation/GET_ORGANISATION_SUCCESS',
    GET_ORGANISATION_ERROR: '@@/organisation/GET_ORGANISATION_ERROR',


    CREATE_ORGANISATION_FIRST: '@@/organisation/CREATE_ORGANISATION_FIRST',
    CREATE_ORGANISATION_LOADING: '@@/organisation/CREATE_ORGANISATION_LOADING',
    CREATE_ORGANISATION_SUCCESS: '@@/organisation/CREATE_ORGANISATION_SUCCESS',
    CREATE_ORGANISATION_ERROR: '@@/organisation/CREATE_ORGANISATION_ERROR',

    UPDATE_ORGANISATION_FIRST: '@@/organisation/UPDATE_ORGANISATION_FIRST',
    UPDATE_ORGANISATION_LOADING: '@@/organisation/UPDATE_ORGANISATION_LOADING',
    UPDATE_ORGANISATION_SUCCESS: '@@/organisation/UPDATE_ORGANISATION_SUCCESS',
    UPDATE_ORGANISATION_ERROR: '@@/organisation/UPDATE_ORGANISATION_ERROR',

    DELETE_ORGANISATION_FIRST: '@@/organisation/DELETE_ORGANISATION_FIRST',
    DELETE_ORGANISATION_LOADING: '@@/organisation/DELETE_ORGANISATION_LOADING',
    DELETE_ORGANISATION_SUCCESS: '@@/organisation/DELETE_ORGANISATION_SUCCESS',
    DELETE_ORGANISATION_ERROR: '@@/organisation/DELETE_ORGANISATION_ERROR',

    RESET_DATA_FIRST: '@@/organisation/RESET_DATA_FIRST'

}