import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { ProfileUpdateActionTypes } from './constants';
import { porfileUpdateEndPoint, forgetPasswordEndPoint } from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* callUpdateProfile(action) {
    try {
        localStorage.removeItem("imagedefault");
        yield put({
            type: ProfileUpdateActionTypes.PROFILE_UPDATE_LOADING,
            payload: {},
        });
        const encryptedPayload = action.data;
        const response = yield call(porfileUpdateEndPoint, encryptedPayload);
        const role = response?.data?.response?.role
        const resp = response?.data
        // localStorage.setItem("imagedefault", defaultImage);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            const defaultImage = response?.picture
            if (role === 'super-admin') {
                localStorage.setItem("super_admin_image", defaultImage);
            } else if (role === 'admin') {
                localStorage.setItem("admin_image", defaultImage);

            } else if (role === 'site-admin') {
                localStorage.setItem("site_admin_image", defaultImage);

            } else if (role === 'user') {
                localStorage.setItem("user_image", defaultImage);

            }
            if (decryptedResponse.status) {
                yield put({
                    type: ProfileUpdateActionTypes.PROFILE_UPDATE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR,
            payload: error,
        });
    }
}

function* callForgetPassword(action) {
    try {
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(forgetPasswordEndPoint, encryptedPayload);

        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        const obj = {
            status: false,
            error: error
        }
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR,
            payload: obj,
        });
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_RESET,
            payload: {},
        });
    }
}
// end cash day Report List


export function* updateProfileAction(): any {
    yield takeEvery(ProfileUpdateActionTypes.PROFILE_UPDATE_FIRST, callUpdateProfile);
}

export function* forgetPasswordAction(): any {
    yield takeEvery(ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_FIRST, callForgetPassword);
}


function* profileUpdateSaga(): any {
    yield all([
        fork(updateProfileAction),
        fork(forgetPasswordAction)
    ]);
}

export default profileUpdateSaga;
