import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminAddSoftwareActionTypes } from './constants';
import {
    adminNdSiteAdminPostAddSoftwareEndPoint,
    adminNdSiteAdminGetSoftwareEndPoint,
    getItDashboardEndPoint,
    ipAddressAdminNdSiteAdminEndPoint,
    getTotalSoftwareAssetsEndPoint,
    getActiveUsers,
    adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint,
} from './api';
import { encryptPayload, decryptPayload } from '../../../CryptoUtil'
// start cash day Report

function* callPostAddSoftwareAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminPostAddSoftwareEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callGetSoftwareAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminGetSoftwareEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

// Total Software && Assets
function* callGetTotalSoftwareAssetsAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(getTotalSoftwareAssetsEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR,
            payload: error,
        });
    }
}

function* callItDashboard(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getItDashboardEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_ERROR,
            payload: error,
        });
    }
}

function* callDefaultItDashboard(data) {
    yield put({
        type: AdminNdSiteAdminAddSoftwareActionTypes.DEFAULT_SET_IT_DASHBOARD_SUCCESS,
        payload: data,
    });
}
function* callPostIpAddressAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(ipAddressAdminNdSiteAdminEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callSuperAdminDashboard(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getActiveUsers, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List

export function* postAddSoftwareAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST,
        callPostAddSoftwareAdminNdSiteAdmin
    );
}

function* callPostVulnerabilitySiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_RESET,
            payload: {},
        });
    }
}



export function* getSoftwareAdminNdSiteAdminActions(): any {
    yield takeEvery(
        AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST,
        callGetSoftwareAdminNdSiteAdmin
    );
}

export function* getItDashboardActions(): any {
    yield takeEvery(AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_FIRST, callItDashboard);
}

export function* defaultSetItDashboardActions(): any {
    yield takeEvery(AdminNdSiteAdminAddSoftwareActionTypes.DEFAULT_SET_IT_DASHBOARD_FIRST, callDefaultItDashboard);
}

export function* postIpAddressAdminNdSiteAdminActions(): any {
    yield takeEvery(
        AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_FIRST,
        callPostIpAddressAdminNdSiteAdmin
    );
}

export function* getTotalSoftwareAssetsAdminNdSiteAdminActions(): any {
    yield takeEvery(
        AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_FIRST,
        callGetTotalSoftwareAssetsAdminNdSiteAdmin
    );
}

export function* getActiveUsersSuperAdminActions(): any {
    yield takeEvery(AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_FIRST, callSuperAdminDashboard);
}

export function* postVulnerabilityAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_FIRST,
        callPostVulnerabilitySiteAdmin
    );
}


function* postAddSoftwareAdminNdSiteAdminSaga(): any {
    yield all([
        fork(postAddSoftwareAdminNdSiteAdminAction),
        fork(getSoftwareAdminNdSiteAdminActions),
        fork(getItDashboardActions),
        fork(defaultSetItDashboardActions),
        fork(postIpAddressAdminNdSiteAdminActions),
        fork(getTotalSoftwareAssetsAdminNdSiteAdminActions),
        fork(getActiveUsersSuperAdminActions),
        fork(postVulnerabilityAction),
    ]);
}

export default postAddSoftwareAdminNdSiteAdminSaga;
