export const HardwareActionTypes = {

    GET_HARDWARE_FIRST: '@@/layout/GET_HARDWARE_FIRST',
    GET_HARDWARE_LOADING: '@@/layout/GET_HARDWARE_LOADING',
    GET_HARDWARE_SUCCESS: '@@/layout/GET_HARDWARE_SUCCESS',
    GET_HARDWARE_ERROR: '@@/layout/GET_HARDWARE_ERROR',
    GET_HARDWARE_RESET: '@@/layout/GET_HARDWARE_RESET',

    CREATE_HARDWARE_FIRST: '@@/layout/CREATE_HARDWARE_FIRST',
    CREATE_HARDWARE_LOADING: '@@/layout/CREATE_HARDWARE_LOADING',
    CREATE_HARDWARE_SUCCESS: '@@/layout/CREATE_HARDWARE_SUCCESS',
    CREATE_HARDWARE_ERROR: '@@/layout/CREATE_HARDWARE_ERROR',
    CREATE_HARDWARE_RESET: '@@/layout/CREATE_HARDWARE_RESET',

    DELETE_HARDWARE_FIRST: '@@/layout/DELETE_HARDWARE_FIRST',
    DELETE_HARDWARE_LOADING: '@@/layout/DELETE_HARDWARE_LOADING',
    DELETE_HARDWARE_SUCCESS: '@@/layout/DELETE_HARDWARE_SUCCESS',
    DELETE_HARDWARE_ERROR: '@@/layout/DELETE_HARDWARE_ERROR',
    DELETE_HARDWARE_RESET: '@@/layout/DELETE_HARDWARE_RESET',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

};