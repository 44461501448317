import { HardwareActionTypes } from './constants';

const GET_HARDWARE_INITIAL_STATE = {
    hardwareData: [],
    loading: false,
};

const getHardwareReducer = (state = GET_HARDWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case HardwareActionTypes.GET_HARDWARE_LOADING:
            return {
                hardwareData: state.hardwareData,
                loading: true,
            };
        case HardwareActionTypes.GET_HARDWARE_SUCCESS:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.GET_HARDWARE_ERROR:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.GET_HARDWARE_RESET:
            return {
                hardwareData: [],
                loading: false,
            };
        default: return state;
    }
}

const createHardwareReducer = (state = GET_HARDWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case HardwareActionTypes.CREATE_HARDWARE_LOADING:
            return {
                hardwareData: state.hardwareData,
                loading: true,
            };
        case HardwareActionTypes.CREATE_HARDWARE_SUCCESS:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.CREATE_HARDWARE_ERROR:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.CREATE_HARDWARE_RESET:
            return {
                hardwareData: GET_HARDWARE_INITIAL_STATE,
                loading: false,
            };
        default:
            return state;
    }
};

const deleteHardwareReducer = (state = GET_HARDWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case HardwareActionTypes.DELETE_HARDWARE_LOADING:
            return {
                hardwareData: state.hardwareData,
                loading: true,
            };
        case HardwareActionTypes.DELETE_HARDWARE_SUCCESS:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.DELETE_HARDWARE_ERROR:
            return {
                hardwareData: action.payload,
                loading: false,
            };
        case HardwareActionTypes.DELETE_HARDWARE_RESET:
            return {
                hardwareData: GET_HARDWARE_INITIAL_STATE,
                loading: false,
            };
        default:
            return state;
    }
};

export { getHardwareReducer, createHardwareReducer, deleteHardwareReducer }