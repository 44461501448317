import { HardwareActionTypes } from './constants';

export const getHardwareActions = (data) => ({
    type: HardwareActionTypes.GET_HARDWARE_FIRST,
    data,
});

export const createHardwareActions = (data) => ({
    type: HardwareActionTypes.CREATE_HARDWARE_FIRST,
    data,
});

export const deleteHardwareActions = (data) => ({
    type: HardwareActionTypes.DELETE_HARDWARE_FIRST,
    data,
});

export const stateEmptyAction = (): LayoutAction => ({
    type: HardwareActionTypes.STATE_EMPTY_INTIAL_STATE,

});