export const AdminNdSiteAdminPlanActionTypes = {
    GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN',
    GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR',

    CREATE_PLAN_ADMIN_ND_SITE_ADMIN: '@@/layout/CREATE_PLAN_ADMIN_ND_SITE_ADMIN',
    CREATE_PLAN_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/CREATE_PLAN_ADMIN_ND_SITE_ADMIN_LOADING',
    CREATE_PLAN_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/CREATE_PLAN_ADMIN_ND_SITE_ADMIN_SUCCESS',
    CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_PLANS_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_PLANS_ADMIN_ND_SITE_ADMIN',
    GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN',
    GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR',

    VERIFY_OTP_ADMIN_ND_SITE_ADMIN: '@@/layout/VERIFY_OTP_ADMIN_ND_SITE_ADMIN',
    VERIFY_OTP_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/VERIFY_OTP_ADMIN_ND_SITE_ADMIN_LOADING',
    VERIFY_OTP_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/VERIFY_OTP_ADMIN_ND_SITE_ADMIN_SUCCESS',
    VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',
};
