import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
import { decryptPayload } from '../../CryptoUtil';

const api = new APICore();

// function adminNdSiteAdminAssetsGetApiEndPoint(params: any): any {
//     const { companyId, search, limit, skip, type, softwareId, } = params?.data;
//     const companyIdToSend = companyId || 1;
//     const softwareIdToSend = softwareId || '';
//     return api.get(`${URL.GET_ASSETS}company_id=${companyIdToSend}&software_id=${softwareIdToSend}&type=${type}&skip=${skip}&limit=${limit}&search=${search}`);
// }

function adminNdSiteAdminAssetsGetApiEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_ASSETS}payload=${encodeURIComponent(encryptedParams)}`);
};


function getSoftwareListEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_SOFTWARE_LIST}/${encodeURIComponent(encryptedParams)}`);
};

// function getSoftwareListEndPoint(params: any): any {
//     const { companyId, } = params?.data;
//     const companyIdToSend = companyId || 1;
//     return api.get(`${URL.GET_SOFTWARE_LIST}/${companyIdToSend}`);
// }

// function adminNdSiteAdminAssetsAddApiEndPoint(params: any): any {
//     const AddAssetsData = params?.data;
//     return api.create(URL.ADD_ASSETS, AddAssetsData);
// }

function adminNdSiteAdminAssetsAddApiEndPoint(encryptedPayload: String): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.ADD_ASSETS, data);
}

function updateAssetsEndPoint(encryptedPayload: any): any {
    const AddAssetsData = {
        payload: encryptedPayload
    };
    return api.update(URL.UPDATE_ASSETS, AddAssetsData);
}

function adminNdSiteAdminAssetsStatusApiEndPoint(encryptedPayload: any): any {
    const statusAssetsData = {
        payload: encryptedPayload
    }
    return api.create(URL.ASSETS_Status, statusAssetsData);
}

function adminNdSiteAdminAssetsCsvApiEndPoint(encryptedPayload: any): any {
    const csvAssetsData = {
        payload: encryptedPayload
    };
    return api.create(URL.ASSETS_CSV, csvAssetsData);
}

function deleteAssetsEndpoint(encryptedPayload: any): any {
    const assetData = {
        payload: encryptedPayload
    };
    return api.create(URL.DELETE_ASSETS, assetData);
}

function getLatestVersion(encryptedPayload: any): any {
    const asset = {
        payload: encryptedPayload
    }
    return api.create(URL.GET_LATEST_VERSION, asset)
}

export {
    adminNdSiteAdminAssetsGetApiEndPoint,
    adminNdSiteAdminAssetsAddApiEndPoint,
    updateAssetsEndPoint,
    adminNdSiteAdminAssetsStatusApiEndPoint,
    adminNdSiteAdminAssetsCsvApiEndPoint,
    getSoftwareListEndPoint,
    deleteAssetsEndpoint,
    getLatestVersion
};
