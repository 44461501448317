import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
import { decryptPayload } from '../../CryptoUtil';

const api = new APICore();

function getDashboardEndPoint(encryptedParams: any): any {
    return api.create(URL.GET_RISKDASHBOARD, encodeURIComponent(encryptedParams));
}


function getVulnerabilityEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_RISK_VULNERABILITY}payload=${encodeURIComponent(encryptedParams)}`);
}

function riskRemedationEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_RISK_REMEDATION, data);
}
function addRiskAssetEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.ADD_RISK_ASSET, data);
}

function japaneseRemedationEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_REMEDATION_JAPANESE, data);
}
export { getDashboardEndPoint, getVulnerabilityEndPoint, riskRemedationEndPoint, japaneseRemedationEndPoint, addRiskAssetEndPoint };
