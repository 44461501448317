import { OrganisationActionTypes } from "./constants";


export const getOrgainsationActions = (data) => ({
    type: OrganisationActionTypes.GET_ORGANISATION_FIRST,
    data

});

export const createOrganisationActions = (data) => ({
    type: OrganisationActionTypes.CREATE_ORGANISATION_FIRST,
    data
})



export const updateOrganisationActions = (data) => ({
    type: OrganisationActionTypes.UPDATE_ORGANISATION_FIRST,
    data
});

export const deleteOrganisationActions = (data) => ({
    type: OrganisationActionTypes.DELETE_ORGANISATION_FIRST,
    data
});


export const resetOrganisationDataActions = () => ({
    type: OrganisationActionTypes.RESET_DATA_FIRST,

});
