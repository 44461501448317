import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminLogsActionTypes } from './constants';
import {
    adminNdSiteAdminGetLogsEndPoint,
} from './api';
import { encryptPayload, decryptPayload } from '../../CryptoUtil'

// start cash day Report

function* callGetLogsAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminGetLogsEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}


function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}


// end cash day Report List


export function* getLogsAdminNdSiteAdminAction(): any {
    yield takeEvery(AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN, callGetLogsAdminNdSiteAdmin);

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getLogsAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getLogsAdminNdSiteAdminAction),
        fork(stateEmptyAction)

    ]);
}

export default getLogsAdminNdSiteAdminSaga;
