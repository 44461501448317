import { RiskDahboardActionTypes } from './constant';

const GET_RISKDASHBOARD_INITIAL_STATE = {
    getRiskdashboard: [],
    loading: false,
};

const GET_VULNERABILITY_INITIAL_STATE = {
    getVulnerability: [],
    loading: false,
};

const POST_RISK_REMEDATION_INITIAL_STATE = {
    riskRemedation: [],
    loading: false,
};

const POST_RISK_ASSET_INITIAL_STATE = {
    riskAsset: [],
    loading: false,
};

const POST_JAPANESE_REMEDATION_INITIAL_STATE = {
    japaneseRemedation: [],
    loading: false,
};

const getRiskdashboardReducer = (state = GET_RISKDASHBOARD_INITIAL_STATE, action) => {
    switch (action.type) {
        case RiskDahboardActionTypes.GET_RISKDASHBOARD_LOADING:
            return {
                getRiskdashboard: state.getRiskdashboard,
                loading: true,
            };
        case RiskDahboardActionTypes.GET_RISKDASHBOARD_SUCCESS:
            return {
                getRiskdashboard: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR:
            return {
                getRiskdashboard: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const getRiskVulnerabilityReducer = (state = GET_VULNERABILITY_INITIAL_STATE, action) => {
    switch (action.type) {
        case RiskDahboardActionTypes.GET_VULNERABILITY_LOADING:
            return {
                getVulnerability: state.getVulnerability,
                loading: true,
            };
        case RiskDahboardActionTypes.GET_VULNERABILITY_SUCCESS:
            return {
                getVulnerability: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.GET_VULNERABILITY_ERROR:
            return {
                getVulnerability: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const riskRemedationReducer = (state = POST_RISK_REMEDATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case RiskDahboardActionTypes.POST_RISK_REMEDATION_LOADING:
            return {
                riskRemedation: state.riskRemedation,
                loading: true,
            };
        case RiskDahboardActionTypes.POST_RISK_REMEDATION_SUCCESS:
            return {
                riskRemedation: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR:
            return {
                riskRemedation: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_RISK_REMEDATION_RESET:
            return POST_RISK_REMEDATION_INITIAL_STATE;
        default:
            return state;
    }
};


const addRiskAssetReducer = (state = POST_RISK_ASSET_INITIAL_STATE, action) => {
    switch (action.type) {
        case RiskDahboardActionTypes.POST_RISK_ASSET_LOADING:
            return {
                riskAsset: state.riskAsset,
                loading: true,
            };
        case RiskDahboardActionTypes.POST_RISK_ASSET_SUCCESS:
            return {
                riskAsset: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_RISK_ASSET_ERROR:
            return {
                riskAsset: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_RISK_ASSET_RESET:
            return POST_RISK_ASSET_INITIAL_STATE;
        default:
            return state;
    }
};

const japaneseRemedationReducer = (state = POST_JAPANESE_REMEDATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_LOADING:
            return {
                japaneseRemedation: state.japaneseRemedation,
                loading: true,
            };
        case RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_SUCCESS:
            return {
                japaneseRemedation: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR:
            return {
                japaneseRemedation: action.payload,
                loading: false,
            };
        case RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_RESET:
            return POST_JAPANESE_REMEDATION_INITIAL_STATE;
        default:
            return state;
    }
};
export { getRiskdashboardReducer, getRiskVulnerabilityReducer, riskRemedationReducer, japaneseRemedationReducer, addRiskAssetReducer };
