import { APICore } from "../../../helpers/api/apiCore";
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getOrganisationEndPoint(encryptedParams: string): any {
    return api.get(`${URL.GET_ORG}payload=${encodeURIComponent(encryptedParams)}`);
};


function createOrganisationEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.CREATE_ORG, data);
};

function updateOrganisationEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.update(URL.UPDATE_ORG, data);
};

function deleteOrganisationEndPoint(encryptedPayload: string): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.DELETE_ORG, data);
};

export {
    getOrganisationEndPoint, createOrganisationEndPoint, updateOrganisationEndPoint, deleteOrganisationEndPoint
}