import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminVChartActionTypes } from './constants';
import {
    adminNdSiteAdminVChartGetApiEndPoint,
    adminNdSiteAdminVChartAddApiEndPoint,
    adminNdSiteAdminVChartEditApiEndPoint,
    adminNdSiteAdminVChartStatusApiEndPoint,
    adminNdSiteAdminVChartCsvApiEndPoint,
} from './api';
import { encryptPayload, decryptPayload } from '../../CryptoUtil';

// start cash day Report

function* callGetVChartByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminVChartGetApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddVChartByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminVChartAddApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditVChartByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminVChartEditApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusVChartByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminVChartStatusApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callCsvVChartByCompanyIdAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(adminNdSiteAdminVChartCsvApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CREATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });
}
// end cash day Report List

export function* getVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callGetVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* addVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callAddVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* EditVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callEditVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* statusVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callStatusVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* csvVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callCsvVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE, callStateEmpty);
}

function* getVChartByCompanyIdAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getVChartByComapnyIdAdminNdSiteAdminAction),
        fork(addVChartByComapnyIdAdminNdSiteAdminAction),
        fork(EditVChartByComapnyIdAdminNdSiteAdminAction),
        fork(statusVChartByComapnyIdAdminNdSiteAdminAction),
        fork(csvVChartByComapnyIdAdminNdSiteAdminAction),
        fork(stateEmptyAction),
    ]);
}

export default getVChartByCompanyIdAdminNdSiteAdminSaga;
