import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetLogsEndPoint(encryptedParams: any): any {
    return api.get(`${URL.LOGS_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

export { adminNdSiteAdminGetLogsEndPoint };
