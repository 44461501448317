// users api end point
export const MY_DEVICES_GET = '/api/instance/user/asset_list';
export const ADD_DEVICES_GET = `/api/instance/user/assest?`;
export const ADD_DEVICES_POST = '/api/users/assets/select';
export const MY_DEVICES_DELETE = '/api/instance/user/asset';
export const MY_DATA_SOURCE_GET = '/api/group/assigndatamgmt';
export const NEW_FILD_GET = '/api/heckernews?';
export const PROFILE_UPDATE = '/api/instance/personalsetting';
export const FORGET_PASSWORD = '/api/profiles/password';

// users api end point

// admin Nd site-admin api end point
export const INSTANCE_GET = '/api/instance?';
export const INSTANCE_ADD = '/api/instance';
export const INSTANCE_EDIT = '/api/instance';
export const INSTANCE_STATUS = '/api/instance/status';
// delete instance
export const INSTANCE_DELETE = '/api/instance/';
// delete instance

export const LICENCE_ADD = '/api/instance/licence/list';
export const ACTIVE_USER = '/api/instance/update/licence';

export const COMPANY_GET = '/api/instance/list';
export const GET_PLAN_BY_COMPANY = '/api/users/planname/';

//V chart
export const ASSETS_GET = '/api/software/asset/list/?';
export const ASSETS_Add = '/api/instance/assests';
export const ASSETS_Edit = '/api/instance/assests';
export const ASSETS_Status = '/api/instance/assests/status';
export const ASSETS_CSV = '/api/csv/assest';
export const DELETE_ASSETS = '/api/software/asset/delete';
export const GET_LATEST_VERSION = '/api/software/asset/latestversion';

// v chart
//ASSETS
export const GET_ASSETS = '/api/software/asset?';
export const ADD_ASSETS = '/api/software/asset/add';
export const GET_SOFTWARE_LIST = '/api/software/list';
export const UPDATE_ASSETS = '/api/software/asset/edit';

export const DATA_MNGMNT_GET = '/api/instance/datamgmt?';
export const DATA_MNGMNT_ADD = '/api/instance/datamgmt';
export const DATA_MNGMNT_EDIT = '/api/instance/datamgmt';
export const DATA_MNGMNT_CSV = '/api/csv/datamgmt';
export const DATA_MNGMNT_STATUS = '/api/instance/datamgmt/status';
export const DATA_MNGMNT_ASSIGN_GROUPS = '/api/instance/datamgmt?';
// export const DATA_MNGMNT_GET_GROUPS = '/api/group/';
export const DATA_MNGMNT_ADD_GROUPS_ASSETS = '/api/group/assigndatasrc';
export const ACCOUNT_GET = '/api/users?';
export const ACCOUNT_ADD = '/api/users';
export const ACCOUNT_EDIT = '/api/users';
export const ACCOUNT_STATUS = '/api/users/status';
export const ACCOUNT_GET_GROUPS = '/api/group/';
export const ACCOUNT_ASSIGN_GROUPS = '/api/group/assignuser';
export const ACCOUNT_USER_PASSWORD_RESET = '/api/users/passwordstatus';
export const LOGS_GET = '/api/logs?';
export const GROUPS_LIST_GET = '/api/group/list?';
export const GROUPS_ADD = '/api/group';
export const GROUPS_EDIT = '/api/group';
export const GROUPS_STATUS = '/api/group/status';
export const GROUPS_DELETE = '/api/group/';
export const GROUPS_GET_MNGMNT = '/api/group/companysource/';
export const GROUPS_GET_USERS = '/api/group/companyuser/';
export const ASSIGN_GROUPS_DELETE = '/api/group/unlink/';
export const POST_SOFTWARE = '/api/software';
export const GET_SOFTWARE = '/api/software';
export const GET_IT_DASHBOARD = '/api/software/itdashboard/data';
export const GET_SOFTARE = '/api/software/?';
export const GET_TASK = '/api/software/vulner/';
export const ADD_TASK = '/api/task';
export const GET_TASK_LIST = '/api/task?';
export const GET_TASK_STATUS = '/api/task/status';
export const ADD_START_TASK = '/api/task';
export const POST_COMMENTS = '/api/task/comment';
export const GET_REPEAT_SCHEDULE = '/api/software/repeat';
export const GET_SOFTWARE_NAME = '/api/software/list/';
export const ADD_SOFTWARE_SCAN = '/api/software/start/scan';
export const GET_USER_MAIL = '/api/software/usermail';
export const DELETE_SOFTWARE = '/api/software/delete';
export const STATUS_SOFTWARE = '/api/software/change/status';
export const GET_USER = '/api/users/list/';

export const TOAT_SOFTWARE_ASSETS_VULN = '/api/software/itdashboards/'
// EDIT SCAN
export const EDIT_SCAN_SCHEDULE = '/api/software/rescan';
// EDIT SCAN
// ***scan code ***
export const GET_SCAN = '/api/software/scan/list?';
export const GET_PREVIOUS_SCAN = '/api/software/previous/scan?';
export const GET_PREVIOUS_ASSET = '/api/software/previous/asset/vuln?';

// start ip Address
export const GET_IP_ADDRESS = '/api/dashboards/ip/list?';
export const GET_IP_ADDRESS_INFO = '/api/dashboards/ip/infolist?_id=';
export const POST_IP_ADDRESS = '/api/dashboards/ip';
export const STATUS_IP_ADDRESS = '/api/dashboards/ip/status';
export const POST_RISK_REMEDATION = '/api/dashboards/risk/remedation';
export const POST_REMEDATION_JAPANESE = '/api/dashboards/risk/remedation/japanese';

//  scan ip Address
export const GET_IP_ADDRESS_SCAN_SCHLDULE = '/api/dashboards/list/ipschedulescan?';
export const GET_PREVIOUS_IP_ADDRESS_SCAN_LIST = '/api/dashboards/ip/previous/scan?';
export const ADD_IP_ADDRESS_SCAN_SCHLDULE = '/api/dashboards/scan/ip';
export const EDIT_IP_ADDRESS_SCAN_SCHLDULE = '/api/dashboards/rescan/ip';
export const GET_IP_ADDRESS_SCAN_SCHLDULE_LIST = '/api/dashboards/list/ips/';
export const ADD_IP_ADDRESS_ON_SCAN_SCHLDULE = '/api/dashboards/ip/scan/ondemand';


// end Ip  Address

// export const ADD_TASK = '/api/task';
// export const GET_TASK_LIST = '/api/task?';
// export const GET_TASK_STATUS = '/api/task/status';
// export const ADD_START_TASK = '/api/task/change/status';
// export const POST_COMMENTS = '/api/task/comment';

// force password api
export const FORCE_PASSWORD = '/api/profiles/password';
export const EMAIL_VERIFY = '/api/auth/verify-email/';
export const RESET_CONFIRM_PASSWORD = '/api/auth/reset-password/';

// admin Nd site-admin api end point
export const ADMIN_CREATE = '/api/admin/';
export const ADMIN_GET = '/api/admin?';
export const ADMIN_UPDATE = '/api/admin/update';

// Super admin API end point
export const GET_ACTIVE_USERS = '/api/admin/dashboard';

// Show Data With Vulnerability and Without Vulnerability or Default
export const POST_VULNERABILITIES_DEFAULT = '/api/software/itdashboard';
// Get Instance Plans
export const GET_INSTANCE_PLANS = '/api/plan/get';
export const GET_RISKDASHBOARD = '/api/software/dashboard/risk';
export const GET_RISK_VULNERABILITY = '/api/dashboards/risk/assets?';
export const ADD_DOCUMENTS = '/api/document/add';
export const ADD_DOCUMENTS_STATUS = '/api/document/status';
export const GET_DOCUMENTS = '/api/document/status';
export const DELETE_DOCUMENTS = '/api/document/delete';
export const ADD_SOFTWARE_ON_SCANNING = '/api/software/scan/ondemand';
export const ADD_RISK_ASSET = '/api/dashboards/risk/assets/status';

//REPORT
export const POST_REPORT = '/api/software/report';
export const GET_REPORT = '/api/admin/report?';
// Multi login
export const GET_PLANS = '/api/users/plan/';
//plan
export const CREATE_PLAN = '/api/plan/create';
export const GET_PLAN_TYPE = '/api/plan/get/planttype';
export const GET_PLAN_SUBSCRIPTION_BY_ID = '/api/plan/subscription/list/';
export const VERIFY_TOKEN = '/api/auth/verify-otp';

// Support
export const GET_SUPPORT = '/api/support/getticket?';
export const GET_SUPPORT_BY_ID = '/api/support/chat/';
export const POST_SUPPORT = '/api/support/addticket';
export const PUT_SUPPORT = '/api/support/editticket';

//hardware
export const GET_HARDWARE = '/api/hardware/get/?';
export const CREATE_HARDWARE = '/api/hardware/add';
export const DELETE_HARDWARE = '/api/hardware/delete';

//ORGANISATION
export const GET_ORG = '/api/admin/orgnisation/get?';
export const CREATE_ORG = '/api/admin/orgnisation/add';
export const UPDATE_ORG = '/api/admin/orgnisation/edit';
export const DELETE_ORG = '/api/admin/orgnisation/delete';