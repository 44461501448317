import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { DocumentsActionTypes } from './constant';
import {
    AddDocuments,
    AddDocumentsStatus,
    getDocuments,
    deleteDocuments
} from './api';

import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* callGetDocumentsSiteAdmin(action) {
    try {
        yield put({
            type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getDocuments, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddDocumenstSiteAdmin(action) {
    try {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = action?.data;

        const response = yield call(AddDocuments, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}
function* callAddDocumenstStatus(action) {
    try {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(AddDocumentsStatus, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET,
            payload: {},
        });
    }
}

function* calldeleteDocumentNdSiteAdmin(action) {
    try {
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(deleteDocuments, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

export function* getDocumentsSiteAdminAction(): any {
    yield takeEvery(
        DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN,
        callGetDocumentsSiteAdmin
    );
}

export function* addDocumentsSiteAdminAction(): any {
    yield takeEvery(
        DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN,
        callAddDocumenstSiteAdmin
    );
}

export function* addDocumentsStatusAction(): any {
    yield takeEvery(
        DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS,
        callAddDocumenstStatus
    );
}

export function* deleteDocument(): any {
    yield takeEvery(
        DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN,
        calldeleteDocumentNdSiteAdmin
    );
}

function* getDocumentsSiteAdminSaga(): any {
    yield all([
        fork(getDocumentsSiteAdminAction),
        fork(addDocumentsSiteAdminAction),
        fork(addDocumentsStatusAction),
        fork(deleteDocument),
    ]);
}

export default getDocumentsSiteAdminSaga;
