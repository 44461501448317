import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { ForcePasswordActionTypes } from './constants';
import {
    putForcePasswordApiEndPoint,
} from './api';
import { encryptPayload, decryptPayload } from '../CryptoUtil';

// start cash day Report

function* callPutForcePassword(action) {
    try {
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(putForcePasswordApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR,
            payload: error,
        });
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_RESET,
            payload: {},
        });
    }
}


// end cash day Report List

export function* putForcePasswordAction(): any {
    yield takeEvery(
        ForcePasswordActionTypes.PUT_FORCE_PASSWORD_FIRST,
        callPutForcePassword
    );
}


function* putForcePasswordSaga(): any {
    yield all([
        fork(putForcePasswordAction)
    ]);
}

export default putForcePasswordSaga;
