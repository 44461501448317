import { reportActionTypes } from "./constants";
import { all, put, fork, takeEvery, takeLatest, call } from "redux-saga/effects";
import { getReport, postReport } from "./api";
import { encryptPayload, decryptPayload } from '../../CryptoUtil'

function* postReportEndpoint(action) {
    try {
        yield put({
            type: reportActionTypes.POST_REPORT_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(postReport, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: reportActionTypes.POST_REPORT_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: reportActionTypes.POST_REPORT_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: reportActionTypes.POST_REPORT_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: reportActionTypes.POST_REPORT_ERROR,
            payload: error,
        })
    }
}

function* getReportEndpoint(action) {
    try {
        yield put({
            type: reportActionTypes.GET_REPORT_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getReport, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: reportActionTypes.GET_REPORT_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: reportActionTypes.GET_REPORT_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: reportActionTypes.GET_REPORT_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: reportActionTypes.GET_REPORT_ERROR,
            payload: error,
        })
    }
}

export function* watchPostReport(): any {
    yield takeEvery(reportActionTypes.POST_REPORT_FIRST, postReportEndpoint)
}
export function* watchGetReport(): any {
    yield takeEvery(reportActionTypes.GET_REPORT_FIRST, getReportEndpoint)
}


function* reportSaga(): any {
    yield all([
        fork(watchPostReport),
        fork(watchGetReport),
    ]);
}

export default reportSaga;