import { APICore } from '../../helpers/api/apiCore';
import * as URL from '../../constants/endPoint';

const api = new APICore();

function putForcePasswordApiEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }
    return api.update(URL.FORCE_PASSWORD, data);
}

export {
    putForcePasswordApiEndPoint,
};
