import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { HardwareActionTypes } from './constants';
import { getHardwareEndPoint, createHardwareEndPoint, deleteHardwareEndPoint } from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';



// function* getHardware(data) {
//     try {
//         yield put({
//             type: HardwareActionTypes.GET_HARDWARE_LOADING,
//             payload: {},
//         });
//         const response = yield call(getHardwareEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: HardwareActionTypes.GET_HARDWARE_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: HardwareActionTypes.GET_HARDWARE_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: HardwareActionTypes.GET_HARDWARE_ERROR,
//             payload: error,
//         });
//     }
// }


function* getHardware(action) {
    try {
        yield put({
            type: HardwareActionTypes.GET_HARDWARE_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(getHardwareEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: HardwareActionTypes.GET_HARDWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: HardwareActionTypes.GET_HARDWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: HardwareActionTypes.GET_HARDWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: HardwareActionTypes.GET_HARDWARE_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}


// function* createHardware(data) {
//     try {
//         yield put({
//             type: HardwareActionTypes.CREATE_HARDWARE_LOADING,
//             payload: {},
//         })
//         const response = yield call(createHardwareEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: HardwareActionTypes.CREATE_HARDWARE_SUCCESS,
//                 payload: { ...response.data },
//             })
//         } else {
//             yield put({
//                 type: HardwareActionTypes.CREATE_HARDWARE_ERROR,
//                 payload: { ...response.data },
//             })
//         }
//     } catch (error) {
//         yield put({
//             type: HardwareActionTypes.CREATE_HARDWARE_ERROR,
//             payload: error,
//         })
//     }
// }


function* createHardware(action) {
    try {
        yield put({
            type: HardwareActionTypes.CREATE_HARDWARE_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(createHardwareEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: HardwareActionTypes.CREATE_HARDWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: HardwareActionTypes.CREATE_HARDWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: HardwareActionTypes.CREATE_HARDWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: HardwareActionTypes.CREATE_HARDWARE_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}


// function* deleteHardware(data) {
//     try {
//         yield put({
//             type: HardwareActionTypes.DELETE_HARDWARE_LOADING,
//             payload: {},
//         })
//         const response = yield call(deleteHardwareEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: HardwareActionTypes.DELETE_HARDWARE_SUCCESS,
//                 payload: { ...response.data },
//             })
//         } else {
//             yield put({
//                 type: HardwareActionTypes.DELETE_HARDWARE_ERROR,
//                 payload: { ...response.data },
//             })
//         }
//     } catch (error) {
//         yield put({
//             type: HardwareActionTypes.DELETE_HARDWARE_ERROR,
//             payload: error,
//         })
//     }
// }

function* deleteHardware(action) {
    try {
        yield put({
            type: HardwareActionTypes.DELETE_HARDWARE_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(deleteHardwareEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: HardwareActionTypes.DELETE_HARDWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: HardwareActionTypes.DELETE_HARDWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: HardwareActionTypes.DELETE_HARDWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: HardwareActionTypes.DELETE_HARDWARE_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}


function* StateEmpty() {
    put({
        type: HardwareActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},

    });
}






export function* getHardwareAction(): any {
    yield takeEvery(HardwareActionTypes.GET_HARDWARE_FIRST, getHardware);
    yield takeEvery(HardwareActionTypes.CREATE_HARDWARE_FIRST, createHardware);
    yield takeEvery(HardwareActionTypes.DELETE_HARDWARE_FIRST, deleteHardware);
    yield takeEvery(HardwareActionTypes.STATE_EMPTY_INTIAL_STATE, StateEmpty);
}

function* hardwareSaga(): any {
    yield all([
        fork(getHardwareAction),
    ]);
}
export default hardwareSaga;