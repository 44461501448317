import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SoftwareActionTypes } from './constants';
import {
    getSoftareEndPoint,
    getTaskEndPoint,
    addTaskEndPoint,
    getScheduleRepeatEndPoint,
    getSoftwareNameEndpoint,
    addScanScheduleEndPoint,
    getUserMailEndpoint,
    getScanEndPoint,
    getPreviousScan,
    getPreviousAsset,
    deleteSoftwareEndPoint,
    editScanScheduleEndPoint,
    statusSoftwareEndPoint,
    addSoftwareScanEndPoint,
    getUserEndpoint,
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* callGetSoftware(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SORTWARE_LOADING,
        });
        // Encrypt the data
        const encryptedPayload = encryptPayload(action.data);
        // Call the API with the encrypted payload
        const response = yield call(getSoftareEndPoint, encryptedPayload);  // Pass encrypted string
        if (response && response.data) {
            // Decrypt the response data
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            // Check status and dispatch success or error
            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_SORTWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_SORTWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SORTWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SORTWARE_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}


function* callGetTaskSoftware(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_TASK_SORTWARE_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(getTaskEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_TASK_SORTWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_TASK_SORTWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_TASK_SORTWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_TASK_SORTWARE_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

function* callAddTaskSoftware(action) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(addTaskEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.ADD_TASK_SORTWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_RESET,
            payload: {},
        });
    }
}

function* callAddScanSchedule(action) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(addScanScheduleEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR,
            payload: error,
        });
        // yield put({
        //     type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
        //     payload: {},
        // });
    }
}

function* callAddSoftwareonScam(action) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(addSoftwareScanEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_RESET,
            payload: {},
        });
    }
}

// scan code
function* callGetScanSchedule(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SCAN_SCHEDULE_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getScanEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_SCAN_SCHEDULE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR,
            payload: error,
        });
    }
}

function* callGetPreviousScan(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_SCAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getPreviousScan, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_PREVIOUS_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR,
            payload: error,
        });
    }
}

function* callGetPreviousAsset(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_ASSET_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getPreviousAsset, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_PREVIOUS_ASSET_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR,
            payload: error,
        });
    }
}

function* callGetRepeatSchedule() {
    try {
        yield put({
            type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_LOADING,
            payload: {},
        });

        const response = yield call(getScheduleRepeatEndPoint);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR,
            payload: error,
        });
    }
}

function* callGetSoftwareName(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SOFTWARE_NAME_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getSoftwareNameEndpoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_SOFTWARE_NAME_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
            payload: error,
        });
    }
}

function* callGetUserMail() {
    try {
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_LOADING,
            payload: {},
        });

        const response = yield call(getUserMailEndpoint);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_USER_MAIL_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_USER_MAIL_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_USER_MAIL_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_RESET,
            payload: {},
        });
    }
}

function* callDeleteSoftware(action) {
    try {
        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.id);

        const response = yield call(deleteSoftwareEndPoint, encryptedPayload);

        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.DELETE_SOFTWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_RESET,
            payload: {},
        });
    }
}
// edit scan
function* callEditScanSchedule(action) {
    try {
        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_LOADING,
            payload: {},
        });

        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(editScanScheduleEndPoint, encryptedPayload);

        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_RESET,
            payload: {},
        });
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
            payload: {},
        });
    }
}

export function* callRescheduleEmpty() {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
            payload: {},
        });
    } catch (error) { }
}

function* callStatusSoftwareAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(statusSoftwareEndPoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.STATUS_SOFTWARE_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_RESET,
            payload: {},
        });
    }
}



function* callGetUser(action) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_USER_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.id);

        const response = yield call(getUserEndpoint, encryptedPayload);
        if (response && response.data) {

            const decryptedResponse = decryptPayload(response.data.encryptedResponse);

            if (decryptedResponse.status) {
                yield put({
                    type: SoftwareActionTypes.GET_USER_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: SoftwareActionTypes.GET_USER_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.GET_USER_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_USER_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.GET_USER_RESET,
            payload: {},
        });
    }
}

// end cash day Report List

export function* getSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SOFTWARE_FIRST, callGetSoftware);
}

export function* getTaskSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_TASK_SOFTWARE_FIRST, callGetTaskSoftware);
}

export function* addTaskSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_TASK_SOFTWARE_FIRST, callAddTaskSoftware);
}
export function* addSoftwareOnScanningAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_SOFTWARE_FOR_SCANNING_FIRST, callAddSoftwareonScam);
}
// export function* getRepeatAction(): any {
//     yield takeEvery(SoftwareActionTypes.GET_REPEAT_FIRST, callGetRepeat);
// }

export function* getScheduleRepeatAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_REPEAT_SCHEDULE_FIRST, callGetRepeatSchedule);
}

export function* getSoftwareNameAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SOFTWARE_NAME_FIRST, callGetSoftwareName);
}

export function* getUserMailAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_USER_MAIL_FIRST, callGetUserMail);
    yield takeEvery(SoftwareActionTypes.GET_USER_FIRST, callGetUser);
}
export function* deleteSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.DELETE_SOFTWARE_FIRST, callDeleteSoftware);
}

export function* addScanScheduleeAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_SCHEDULE_SCAN_FIRST, callAddScanSchedule);
}

// scan code
export function* getScanAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SCAN_SCHEDULE_FIRST, callGetScanSchedule);
    yield takeEvery(SoftwareActionTypes.GET_PREVIOUS_SCAN_FIRST, callGetPreviousScan);
    yield takeEvery(SoftwareActionTypes.GET_PREVIOUS_ASSET_FIRST, callGetPreviousAsset);
}
// edit scan
export function* EditScanScheduleAction(): any {
    yield takeEvery(SoftwareActionTypes.EDIT_SCHEDULE_SCAN_FIRST, callEditScanSchedule);
}

export function* emptyRescheduleStateActionss(): any {
    yield takeEvery(SoftwareActionTypes.EMPTY_STATE_RESCHEDULE_FIRST, callRescheduleEmpty);
}
export function* statusSoftwareStateActionss(): any {
    yield takeEvery(SoftwareActionTypes.STATUS_SOFTWARE_FIRST, callStatusSoftwareAdminNdSiteAdmin);
}

function* getSoftwareSaga(): any {
    yield all([
        fork(getSoftwareAction),
        fork(getTaskSoftwareAction),
        fork(addTaskSoftwareAction),
        fork(getScheduleRepeatAction),
        fork(getSoftwareNameAction),
        fork(addScanScheduleeAction),
        fork(getUserMailAction),
        fork(deleteSoftwareAction),
        fork(getScanAction),
        fork(EditScanScheduleAction),
        fork(emptyRescheduleStateActionss),
        fork(statusSoftwareStateActionss),
        fork(addSoftwareOnScanningAction),
    ]);
}

export default getSoftwareSaga;
