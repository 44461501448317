import { APICore } from '../../../helpers/api/apiCore';
import * as URL from "../../../constants/endPoint"
import { decryptPayload } from '../../CryptoUtil';

const api = new APICore();

function getTaskListEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_TASK_LIST}payload=${encodeURIComponent(encryptedParams)}`);
}


function getTaskStatusEndPoint(params: any): any {
    return api.get(URL.GET_TASK_STATUS);
}

function addStartTaskEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }
    return api.update(URL.ADD_START_TASK, data);
}
function addCommentEndPoint(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    }
    return api.create(URL.POST_COMMENTS, data);
}

export {
    getTaskListEndPoint,
    getTaskStatusEndPoint,
    addStartTaskEndPoint,
    addCommentEndPoint
}