import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminPlanActionTypes } from './constants';
import {
    createPlanEndPoint,
    getPlansEndPoint,
    getPlanSubscriptionByIdEndPoint,
    getPlanSubscriptionEndPoint,
    getPlanTypeEndPoint,
    verifyOtpEndPoint,
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';

// function* getPlansWhileLogin(data) {
//     try {
//         yield put({
//             type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING,
//             payload: {},
//         });
//         const response = yield call(getPlansEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
//                 payload: { ...response.data },
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
//             payload: error,
//         });
//     }
// }

function* getPlansWhileLogin(action) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(getPlansEndPoint, encodeURIComponent(encryptedPayload));
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

function* getPlanType(action) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(getPlanTypeEndPoint, encodeURIComponent(encryptedPayload));

        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}


function* createPlans(action) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_LOADING,
        });
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(createPlanEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

function* getPlanSubscriptionById(action) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
        });
        const encryptedPayload = encryptPayload(action.data);
        const response = yield call(getPlanSubscriptionByIdEndPoint, encryptedPayload);

        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: { message: error.message || 'An error occurred' },
        });
    }
}

function* callVerifyOtp(action) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(verifyOtpEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,
            payload: {},
        });
    }
}



function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* getPlansAction(): any {
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN, getPlansWhileLogin);
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN, getPlanType);
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN, getPlanSubscriptionById);
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN, createPlans);
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN, callVerifyOtp);

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getPlansSaga(): any {
    yield all([
        fork(getPlansAction),
        fork(stateEmptyAction)

    ]);
}

export default getPlansSaga;
