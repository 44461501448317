export const RiskDahboardActionTypes = {
    GET_RISKDASHBOARD_FIRST: '@@/layout/GET_RISKDASHBOARD_ADMIN_ND_SITE_ADMIN',
    GET_RISKDASHBOARD_LOADING: '@@/layout/GET_RISKDASHBOARD_LOADING',
    GET_RISKDASHBOARD_SUCCESS: '@@/layout/GET_RISKDASHBOARD_SUCCESS',
    GET_RISKDASHBOARD_ERROR: '@@/layout/GET_RISKDASHBOARD_ERROR',

    GET_VULNERABILITY_FIRST: '@@/layout/GET_VULNERABILITY_ADMIN_ND_SITE_ADMIN',
    GET_VULNERABILITY_LOADING: '@@/layout/GET_VULNERABILITY_LOADING',
    GET_VULNERABILITY_SUCCESS: '@@/layout/GET_VULNERABILITY_SUCCESS',
    GET_VULNERABILITY_ERROR: '@@/layout/GET_VULNERABILITY_ERROR',


    // RISK REMEDATION API DASHBOARD
    POST_RISK_REMEDATION_FIRST: '@@/layout/POST_RISK_REMEDATION_FIRST',
    POST_RISK_REMEDATION_LOADING: '@@/layout/POST_RISK_REMEDATION_LOADING',
    POST_RISK_REMEDATION_SUCCESS: '@@/layout/POST_RISK_REMEDATION_SUCCESS',
    POST_RISK_REMEDATION_ERROR: '@@/layout/POST_RISK_REMEDATION_ERROR',
    POST_RISK_REMEDATION_RESET: '@@/layout/POST_RISK_REMEDATION_RESET',

    POST_JAPANESE_REMEDATION_FIRST: '@@/layout/POST_JAPANESE_REMEDATION_FIRST',
    POST_JAPANESE_REMEDATION_LOADING: '@@/layout/POST_JAPANESE_REMEDATION_LOADING',
    POST_JAPANESE_REMEDATION_SUCCESS: '@@/layout/POST_JAPANESE_REMEDATION_SUCCESS',
    POST_JAPANESE_REMEDATION_ERROR: '@@/layout/POST_JAPANESE_REMEDATION_ERROR',
    POST_JAPANESE_REMEDATION_RESET: '@@/layout/POST_JAPANESE_REMEDATION_RESET',

    POST_RISK_ASSET_FIRST: '@@/layout/POST_RISK_ASSET_FIRST',
    POST_RISK_ASSET_LOADING: '@@/layout/POST_RISK_ASSET_LOADING',
    POST_RISK_ASSET_SUCCESS: '@@/layout/POST_RISK_ASSET_SUCCESS',
    POST_RISK_ASSET_ERROR: '@@/layout/POST_RISK_ASSET_ERROR',
    POST_RISK_ASSET_RESET: '@@/layout/POST_RISK_ASSET_RESET',
}
