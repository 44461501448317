import { AdminNdSiteAdminPlanActionTypes } from "./constants";

export const getPlan = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN,
    data
});
export const getPlanType = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_TYPE_ADMIN_ND_SITE_ADMIN,
    data
});

export const getPlanSubscriptionById = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.GET_PLAN_SUBSCRIPTION_BY_ID_ADMIN_ND_SITE_ADMIN,
    data
});
export const createPlan = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.CREATE_PLAN_ADMIN_ND_SITE_ADMIN,
    data
});
export const verifyOtp = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.VERIFY_OTP_ADMIN_ND_SITE_ADMIN,
    data
});

export const stateEmptyAction = (): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,

});


