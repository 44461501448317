import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { CreatAdminActionTypes } from './constants';
import { createAdminApiEndPoint, getAdminApiEndPoint, updateAdminApiEndPoint } from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';



function* callCreateAdmin(action) {
    try {
        yield put({
            type: CreatAdminActionTypes.CREATE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(createAdminApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: CreatAdminActionTypes.CREATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: CreatAdminActionTypes.CREATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: CreatAdminActionTypes.CREATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.CREATE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callUpdateAdmin(action) {
    try {
        yield put({
            type: CreatAdminActionTypes.UPDATE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(updateAdminApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: CreatAdminActionTypes.UPDATE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: CreatAdminActionTypes.UPDATE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: CreatAdminActionTypes.UPDATE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.UPDATE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callGetAdmin(action) {
    try {
        yield put({
            type: CreatAdminActionTypes.GET_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action.data);

        const response = yield call(getAdminApiEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: CreatAdminActionTypes.GET_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: CreatAdminActionTypes.GET_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: CreatAdminActionTypes.GET_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.GET_ADMIN_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List
function* callStateEmpty() {
    put({
        type: CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}

export function* ActionCreateAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.CREATE_ADMIN_FIRST, callCreateAdmin);
}

export function* ActionGetAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.GET_ADMIN_FIRST, callGetAdmin);
}

export function* ActionUpdateAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.UPDATE_ADMIN_FIRST, callUpdateAdmin);
}

function* AdminSaga(): any {
    yield all([fork(ActionCreateAdmin), fork(ActionGetAdmin), fork(ActionUpdateAdmin), fork(stateEmptyAction)]);
}

export default AdminSaga;
