import { AdminNdSiteAdminLogsActionTypes } from "./constants";

export const getLogsAdminNdSiteAdminActions = (data) => ({
    type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN,
    data

});

export const stateEmptydAction = () => ({
    type: AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,

});


