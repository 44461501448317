import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { IpAddressActionTypes } from './constants';
import {
    getIpAddressEndPoint,
    getIpAddressInfoEndPoint,
    getIpAddressScanEndPoint,
    addIpScanScheduleEndPoint,
    editIpScanScheduleEndPoint,
    getIpAddressScanListEndPoint,
    getPreviousIpAddressScanListEndPoint,
    statusIpAddressEndPoint,
    addIpScanEndPoint,
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';


function* CallGetIpAddress(action) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getIpAddressEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressInfo(action) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.id);

        const response = yield call(getIpAddressInfoEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressScan(action) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getIpAddressScanEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressScanList(action) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getIpAddressScanListEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR,
            payload: error,
        });
    }
}

function* CallGetPreviousIpAddressScanList(action) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getPreviousIpAddressScanListEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR,
            payload: error,
        });
    }
}
function* callAddIpScanSchedule(action) {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(addIpScanScheduleEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
    }
}

function* callIponScam(action) {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(addIpScanEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.ADD_TASK_IP_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_RESET,
            payload: {},
        });
    }
}

function* callEditIpScanSchedule(action) {
    try {
        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(editIpScanScheduleEndPoint, encryptedPayload);

        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
    }
}
function* callStatusIpAddressAdminNdSiteAdmin(action) {
    try {
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(statusIpAddressEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: IpAddressActionTypes.STATUS_IP_ADDRESS_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_RESET,
            payload: {},
        });
    }
}

export function* callRescheduleIpEmpty() {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
    } catch (error) { }
}
export function* getIpAddressAction(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_FIRST, CallGetIpAddress);
}
export function* getIpAddressInfoAction(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_INFO_FIRST, CallGetIpAddressInfo);
}
export function* getIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_SCAN_FIRST, CallGetIpAddressScan);
}
export function* getIpAddressScanListActionss(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_FIRST, CallGetIpAddressScanList);
}

export function* getPreviousIpAddressScanListActions(): any {
    yield takeEvery(IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_FIRST, CallGetPreviousIpAddressScanList);
}

export function* addIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_FIRST, callAddIpScanSchedule);
}
export function* editIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_FIRST, callEditIpScanSchedule);
}
export function* statusIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.STATUS_IP_ADDRESS_FIRST, callStatusIpAddressAdminNdSiteAdmin);
}

export function* emptyRescheduleIpStateActionss(): any {
    yield takeEvery(IpAddressActionTypes.EMPTY_STATE_RESCHEDULE_IP_FIRST, callRescheduleIpEmpty);
}

export function* addIpOnScanningAction(): any {
    yield takeEvery(IpAddressActionTypes.ADD_IP_FOR_SCANNING_FIRST, callIponScam);
}

function* getIpAddressSaga(): any {
    yield all([
        fork(getIpAddressAction),
        fork(getIpAddressInfoAction),
        fork(getIpAddressScanActionss),
        fork(addIpAddressScanActionss),
        fork(editIpAddressScanActionss),
        fork(emptyRescheduleIpStateActionss),
        fork(getIpAddressScanListActionss),
        fork(statusIpAddressScanActionss),
        fork(getPreviousIpAddressScanListActions),
        fork(addIpOnScanningAction),
    ]);
}
export default getIpAddressSaga;
