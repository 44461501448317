import { OrganisationActionTypes } from "./constants";

// Initial states
const ORGANISATION_DATA_INITIAL_STATE = {
    organisationData: [],
    loading: false,
    error: null,
};

const CREATE_ORGANISATION_INITIAL_STATE = {
    createdOrganisation: null,
    loading: false,
    error: null,
};

const UPDATE_ORGANISATION_INITIAL_STATE = {
    updatedOrganisation: null,
    loading: false,
    error: null,
};

const DELETE_ORGANISATION_INITIAL_STATE = {
    deletedOrganisation: null,
    loading: false,
    error: null,
};

const getOrganisationReducer = (state = ORGANISATION_DATA_INITIAL_STATE, action) => {
    switch (action.type) {
        case OrganisationActionTypes.GET_ORGANISATION_LOADING:
            return { ...state, loading: true, error: null };
        case OrganisationActionTypes.GET_ORGANISATION_SUCCESS:
            return { ...state, organisationData: action.payload, loading: false };
        case OrganisationActionTypes.GET_ORGANISATION_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

const createOrganisationReducer = (state = CREATE_ORGANISATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case OrganisationActionTypes.CREATE_ORGANISATION_LOADING:
            return { ...state, loading: true, error: null };
        case OrganisationActionTypes.CREATE_ORGANISATION_SUCCESS:
            return { ...state, createdOrganisation: action.payload, loading: false };
        case OrganisationActionTypes.CREATE_ORGANISATION_ERROR:
            return { ...state, error: action.payload, loading: false };
        case OrganisationActionTypes.RESET_DATA_FIRST:
            return { ...CREATE_ORGANISATION_INITIAL_STATE };

        default:
            return state;
    }
};

const updateOrganisationReducer = (state = UPDATE_ORGANISATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case OrganisationActionTypes.UPDATE_ORGANISATION_LOADING:
            return { ...state, loading: true, error: null };
        case OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS:
            return { ...state, updatedOrganisation: action.payload, loading: false };
        case OrganisationActionTypes.UPDATE_ORGANISATION_ERROR:
            return { ...state, error: action.payload, loading: false };
        case OrganisationActionTypes.RESET_DATA_FIRST:
            return { ...UPDATE_ORGANISATION_INITIAL_STATE };
        default:
            return state;
    }
};

const deleteOrganisationReducer = (state = DELETE_ORGANISATION_INITIAL_STATE, action) => {
    switch (action.type) {
        case OrganisationActionTypes.DELETE_ORGANISATION_LOADING:
            return { ...state, loading: true, error: null };
        case OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS:
            return { ...state, deletedOrganisation: action.payload, loading: false };
        case OrganisationActionTypes.DELETE_ORGANISATION_ERROR:
            return { ...state, error: action.payload, loading: false };
        case OrganisationActionTypes.RESET_DATA_FIRST:
            return { ...DELETE_ORGANISATION_INITIAL_STATE };
        default:
            return state;
    }
};

export {
    getOrganisationReducer,
    createOrganisationReducer,
    updateOrganisationReducer,
    deleteOrganisationReducer,
};
