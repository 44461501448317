import { APICore } from '../../../helpers/api/apiCore';
import * as URL from "../../../constants/endPoint"

const api = new APICore();

function porfileUpdateEndPoint(encryptedPayload: any): any {
    // const upateData = {
    //     payload: encryptedPayload
    // }
    return api.create(URL.PROFILE_UPDATE, encryptedPayload);
}

function forgetPasswordEndPoint(encryptedPayload: any): any {
    const upateData = {
        payload: encryptedPayload
    }
    return api.update(URL.FORGET_PASSWORD, upateData);
}



export { porfileUpdateEndPoint, forgetPasswordEndPoint }