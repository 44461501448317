import { Navigate } from 'react-router-dom';
import { getUserFromSession } from '../helpers/api/apiCore';

const RootFind = () => {
    const user = getUserFromSession();
    const otpStatus = sessionStorage.getItem('otp_verified')
    console.log({ otpStatus })
    // Check if OTP verification is required
    const otpRequired = user?.data?.status;
    console.log({ otpRequired })

    if (!user || !user.token) {
        return 'account/login';
    }

    // Force OTP verification before accessing any dashboard
    if (otpRequired) {
        return 'account/verify-otp';
    }

    // Handle force password change
    const forcePasswordCheck = user?.data?.data?.user?.forcePassword;
    if (forcePasswordCheck === 1) {
        return 'account/forgetpassword';
    }
    // Role-based redirection
    if (user?.role === 'super-admin') {
        return 'superadmin/dashboard/ciodashboard';
    } else if (user?.role === 'admin') {
        return 'admin/dashboard/instance';
    } else if (user?.role === 'site-admin') {
        if (user?.data?.user?.planname === 'Eagle Eye') {
            return 'siteadmin/dashboard/riskdashboard';
        } else if (user?.data?.user?.planname === 'Eagle Talon') {
            return 'siteadmin/dashboard/itdashboard';
        }
    } else if (user?.role === 'user') {
        if (user?.data?.user?.planname === 'Eagle Eye') {
            return 'user/dashboard/riskdashboard';
        } else if (user?.data?.user?.planname === 'Eagle Talon') {
            return 'user/dashboard/itdashboard';
        }
    }

    // Default to login if no role matches
    return 'account/login';
};

const Root = () => {
    const getRootUrl = () => {
        return RootFind();
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
