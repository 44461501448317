import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getDocuments(encryptedParams: any): any {
    return api.get(`${URL.GET_DOCUMENTS}?payload=${encodeURIComponent(encryptedParams)}`);
}

function AddDocuments(encryptedPayload: any): any {
    // const documentAddData = {
    //     payload: encryptedPayload
    // };
    return api.create(URL.ADD_DOCUMENTS, encryptedPayload);
}
function AddDocumentsStatus(encryptedPayload: any): any {
    const documentStatus = {
        payload: encryptedPayload

    };
    return api.create(URL.ADD_DOCUMENTS_STATUS, documentStatus);
}

function deleteDocuments(encryptedPayload: any): any {
    const id = {
        payload: encryptedPayload
    };
    return api.create(URL.DELETE_DOCUMENTS, id);
}

export {
    getDocuments,
    AddDocuments,
    AddDocumentsStatus,
    deleteDocuments
};
