import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { AdminNdSiteAdminSupportActionTypes } from './constants';
import {
    getSupportEndPoint,
    createSupportEndpoint,
    getSupportByIdEndPoint,
    updateSupportEndpoint
} from './api';
import { decryptPayload, encryptPayload } from '../../CryptoUtil';

function* getSupport(action) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getSupportEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}
function* getSupportById(action) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(getSupportByIdEndPoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* createSupport(action) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);

        const response = yield call(createSupportEndpoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        })
    }
}
function* updateSupport(action) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        })
        const encryptedPayload = encryptPayload(action?.data);
        const response = yield call(updateSupportEndpoint, encryptedPayload);
        if (response && response.data) {
            const decryptedResponse = decryptPayload(response.data.encryptedResponse);
            if (decryptedResponse.status) {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                    payload: decryptedResponse,
                });
            } else {
                yield put({
                    type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                    payload: decryptedResponse,
                });
            }
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { message: 'No response data' },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        })
    }
}


function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* getSupportAction(): any {
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN, getSupport);
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN, getSupportById);
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN, createSupport);
    yield takeLatest(AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN, updateSupport);
}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getSupportSaga(): any {
    yield all([
        fork(getSupportAction),
        fork(stateEmptyAction)

    ]);
}

export default getSupportSaga;
