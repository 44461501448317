import { AdminNdSiteAdminCompanyActionTypes } from './constants';

export const getCompanyAction = (id): LayoutAction => ({
    type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN,
    id,
});

export const getPlanByCompanyAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminCompanyActionTypes.GET_PLAN_WITH_COMAPNY_ID,
    data,
});
