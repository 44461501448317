import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetInstanceEndPoint(encryptedParams: any): any {
    return api.get(`${URL.INSTANCE_GET}payload=${encodeURIComponent(encryptedParams)}`);
}

function getInstancePlansEndPoint(): any {
    return api.get(URL.GET_INSTANCE_PLANS);
}

function adminNdSiteAdminAddInstanceEndPoint(encryptedPayload: any): any {
    const instanceAddData = {
        payload: encryptedPayload
    };
    return api.create(URL.INSTANCE_ADD, instanceAddData);
}

function adminNdSiteAdminEditInstanceEndPoint(encryptedPayload: any): any {
    const instanceEditData = {
        payload: encryptedPayload
    };
    return api.update(URL.INSTANCE_EDIT, instanceEditData);
}

function adminNdSiteAdminStatusInstanceEndPoint(encryptedPayload: any): any {
    const instanceStatusData = {
        payload: encryptedPayload
    };
    return api.create(URL.INSTANCE_STATUS, instanceStatusData);
}

function adminNdSiteAdminDeleteInstanceEndPoint(encryptedPayload: any): any {
    // const id = {
    //     payload: encryptedPayload
    // };
    return api.create(`${URL.INSTANCE_DELETE}${encryptedPayload}`);
}
function AddLicenceEndPoint(encryptedPayload: any): any {
    const LicenceAddData = {
        payload: encryptedPayload
    };
    return api.create(URL.LICENCE_ADD, LicenceAddData);
}

function activeEndPoint(encryptedPayload: any): any {
    const activeUser = {
        payload: encryptedPayload
    };
    return api.create(URL.ACTIVE_USER, activeUser);
}

export {
    adminNdSiteAdminGetInstanceEndPoint,
    adminNdSiteAdminAddInstanceEndPoint,
    adminNdSiteAdminEditInstanceEndPoint,
    adminNdSiteAdminStatusInstanceEndPoint,
    adminNdSiteAdminDeleteInstanceEndPoint,
    AddLicenceEndPoint,
    activeEndPoint,
    getInstancePlansEndPoint
};
