import { APICore, getUserFromSession } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();
const user = getUserFromSession();
// const role = user?.role

function getSupportEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_SUPPORT}payload=${encodeURIComponent(encryptedParams)}`);
}
// role === 'admin' && role === 'super-admin' ? ('') : (CompanyId)

function getSupportByIdEndPoint(encryptedParams: any): any {
    return api.get(`${URL.GET_SUPPORT_BY_ID}${encodeURIComponent(encryptedParams)}`);
}

function createSupportEndpoint(encryptedPayload: any): any {
    const SupportData = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_SUPPORT, SupportData);
}
function updateSupportEndpoint(encryptedPayload: any): any {
    const SupportData = {
        payload: encryptedPayload
    };
    return api.update(URL.PUT_SUPPORT, SupportData);
}


export { getSupportEndPoint, createSupportEndpoint, getSupportByIdEndPoint, updateSupportEndpoint };
