import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
// import { decryptPayload, encryptPayload } from '../../CryptoUtil';

const api = new APICore();

function adminNdSiteAdminComapnyApiEndPoint(): any {
    return api.get(URL.COMPANY_GET);
}

function getPlanWithCompanyIdEndpoint(encryptedParams): any {
    return api.get(`${URL.GET_PLAN_BY_COMPANY}${encodeURIComponent(encryptedParams)}`);
}

export { adminNdSiteAdminComapnyApiEndPoint, getPlanWithCompanyIdEndpoint };
