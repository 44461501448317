export const AdminNdSiteAdminCompanyActionTypes = {
    GET_COMAPNY_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_COMAPNY_ADMIN_ND_SITE_ADMIN',
    GET_COMAPNY_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_COMAPNY_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_COMAPNY_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_COMAPNY_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_PLAN_WITH_COMAPNY_ID: '@@/layout/GET_PLAN_WITH_COMAPNY_ID',
    GET_PLAN_WITH_COMAPNY_ID_LOADING: '@@/layout/GET_PLAN_WITH_COMAPNY_ID_LOADING',
    GET_PLAN_WITH_COMAPNY_ID_SUCCESS: '@@/layout/GET_PLAN_WITH_COMAPNY_ID_SUCCESS',
    GET_PLAN_WITH_COMAPNY_ID_ERROR: '@@/layout/GET_PLAN_WITH_COMAPNY_ID_ERROR',
};
