import { APICore } from "../../../helpers/api/apiCore";
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function postReport(encryptedPayload: any): any {
    const data = {
        payload: encryptedPayload
    };
    return api.create(URL.POST_REPORT, data)
}
function getReport(encryptedParams: string): any {
    return api.get(`${URL.GET_REPORT}payload=${encodeURIComponent(encryptedParams)}`);
};
export {
    postReport, getReport
}